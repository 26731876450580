import React from "react";
import Navbar from "../../Components/Navbar";
import Hero from "./components/Hero";
import AboutUs from "./components/AboutUs";
import Services from "./components/Services";
import JoinUs from "./components/JoinUs";
import MetaData from "../../Components/MetaData";

export default function Home() {
  return (
    <>
    <MetaData title="Home"/>
      <Navbar />
      <Hero />
      <div className="svg-bg" >
        <AboutUs />
        <Services />
      </div>
      <JoinUs />
    </>
  );
}
