import React from "react";

export default function Hero() {
  return (
    <>
      <div
        className="container-fluid bg-image-top"
        style={{ backgroundImage: `url(/Images/12.jpg)` }}
      >
        <div
          style={{ minHeight: "100vh" }}
          className="container bg-image-top-container justify-content-end d-flex align-items-center flex-wrap"
        >
          <div className="col-lg-5 col-md-7 ">
            <b>
              <h1>Design Thinking & People Empowering</h1>
            </b>
            <p className="justify">
              Our design thinking and people empowering service is a
              collaborative approach that fosters innovation, creativity, and
              problem-solving by putting the user at the center of the process.
            </p>
            <div className="col-12 d-flex justify-content-around flex-wrap">
              <a href="#design" className="btn bg-light design-btns rounded-5 my-3 ">
                <b className="primary-color">Design Thinking</b>
              </a>
              <a href="#people" className="btn bg-light design-btns rounded-5 my-3 ">
                <b className="primary-color">People Empowering</b>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
