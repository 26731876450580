import "./App.css";
import Home from "./Pages/Home/Home";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Advisor from "./Pages/Advisor/Advisor";
import Design from "./Pages/Design/Design";
import ScrollToTop from "./Components/ScrollToTop";
import Marketing from "./Pages/Marketing/Marketing";
import ScrollToTopButton from "./Components/ScrollToTopButton";
import Cookies from "./Pages/Cookies";
import Privacy from "./Pages/Privacy";
import LegalNotice from "./Pages/LegalNotice";
import CookieBanner from "./Components/CookieBanner";

function App() {

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="advisor" element={<Advisor/>} />
          <Route path="design" element={<Design/>} />
          <Route path="marketing" element={<Marketing/>} />
          <Route path="cookie-policy" element={<Cookies/>} />
          <Route path="privacy-policy" element={<Privacy/>} />
          <Route path="legal-notice" element={<LegalNotice/>} />
        </Routes>
        <Contact />
        <Footer />
        <ScrollToTop/>
        <ScrollToTopButton/>
        <CookieBanner/>
      </Router>
    </>
  );
}

export default App;
