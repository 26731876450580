import React from "react";
import { Link } from "react-router-dom";

export default function LegalPageNavbar(props) {
  return (
    <>
      <nav class="navbar fixed-top container-fluid navbar-expand-lg bg-transparent">
        <div class="container py-2 border-bottom">
          <Link class="navbar-brand" href="/">
            <img src="/Images/3x/logo-purple.png" width={80} />
          </Link>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav mx-auto mb-2 mb-lg-0 nav-fill">
              <li class="nav-item mx-3">
                <Link class="nav-link legal-page-nav active" aria-current="page" to="/">
                  Home
                </Link>
              </li>

              <li class="nav-item mx-3 dropdown">
                <a
                  class="nav-link legal-page-nav dropdown-toggle"
                  href="#"
                  id="servicesDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                >
                  Services
                </a>
                <ul
                  class="dropdown-menu dropdown-blured"
                  aria-labelledby="servicesDropdown"
                >
                  <li>
                    <Link class="dropdown-item" to="/advisor">
                      Business advisor
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="/advisor">
                      Market analysis
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="/design">
                      Design thinking
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="/design">
                      People empowering
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="/marketing">
                      Digital marketing
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="/marketing">
                      Seo Marketing
                    </Link>
                  </li>
                </ul>
              </li>

              {props.links &&
                props.links.map((link) => (
                  <li class="nav-item mx-3">
                    <a class="nav-link legal-page-nav" href={link.link}>
                      {link.title}
                    </a>
                  </li>
                ))}

              <li class="nav-item mx-3">
                <a class="nav-link legal-page-nav" href="#contact">
                  Contact Us
                </a>
              </li>
            </ul>

            <ul class="navbar-nav">
              <li class="nav-item dropdown">
                <a
                  class="nav-link legal-page-nav dropdown-toggle"
                  href="#"
                  id="languageDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                >
                  Eng
                </a>
                <ul
                  class="dropdown-menu dropdown-blured"
                  aria-labelledby="languageDropdown"
                >
                  <li>
                    <a class="dropdown-item" href="#">
                      English
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Spanish
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      French
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
