import React from "react";
import LegalPageNavbar from "../Components/LegalPageNavbar";
import MetaData from "../Components/MetaData";

export default function Privacy() {
  return (
    <>
      <MetaData title="Privacy Policy" />
      <LegalPageNavbar />
      <div
        style={{
          backgroundImage: `url(/Images/FhpeXInXEAEvFME.jpg)`,
        }}
        className="container rounded-5 shadow cookies bg-image-top-container justify-content-center d-flex align-items-center flex-wrap"
      >
        <h1 className="primary-color cookie-heading">
          OSIRIS INTERNATIONAL LLC PRIVACY POLICY
        </h1>
        <div
          className="p-3 dashed-border"
          style={{
            minHeight: "100vh",
            width: "50%",
            position: "absolute",
            left: "-20px",
          }}
        ></div>
      </div>
      <div className="container py-5 cookie-content">
        <p>
          At OSIRIS INTERNATIONAL LLC, we highly value your privacy and are
          committed to safeguarding your personal information. This Privacy
          Policy outlines how we collect, use, and share your personal
          information obtained through our website and online services. By using
          our website or services, you agree to the terms of this Privacy
          Policy.
        </p>

        <h2>Personal Information Collection</h2>
        <p>
          We collect personal information directly from you through our website
          or services, such as your name, email address, mailing address, phone
          number, and other contact information. We may also collect information
          about your website and services usage, such as your IP address,
          browser type, operating system, pages visited, location information,
          and other log information.
        </p>

        <h2>Personal Information Usage</h2>
        <p>
          We use your personal information to provide you with our products and
          services, respond to your inquiries, personalize your user experience,
          administer your account, and send you promotional communications. We
          may also use your personal information for internal purposes, such as
          data analytics and compliance with legal obligations.
        </p>

        <h2>Personal Information Disclosure</h2>
        <p>
          We may share your personal information with third-party service
          providers that assist us in providing our products and services, such
          as hosting, payment processing, and marketing services. We may also
          disclose your personal information when necessary to comply with
          applicable law, respond to a court order, or protect our legal rights.
        </p>

        <h2>Personal Information Security</h2>
        <p>
          We are dedicated to protecting the security of your personal
          information and have implemented reasonable technical and
          organizational measures to prevent unauthorized access, alteration,
          disclosure, or misuse.
        </p>

        <h2>Personal Information Retention</h2>
        <p>
          We keep your personal information for as long as necessary to fulfill
          the purposes for which it was collected or to comply with legal
          obligations, unless a longer retention period is required or permitted
          by law.
        </p>

        <h2>Privacy Rights</h2>
        <p>
          You have certain privacy rights concerning your personal information,
          such as the right to access, rectify, and delete your personal
          information. You also have the right to object to the processing of
          your personal information in certain circumstances. If you wish to
          exercise any of these rights, please contact us using the contact
          information provided below.
        </p>

        <h2>Privacy Policy Changes</h2>
        <p>
          We may revise this Privacy Policy from time to time to reflect changes
          in our privacy practices. We recommend that you periodically review
          this Privacy Policy to stay informed of any significant changes. If we
          make any significant changes, we will notify you by posting an updated
          version on our website.
        </p>

        <h2>Contact Us</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy,
          please contact us through:
        </p>
        <p>
          Company Address:
          <br />
          OSIRIS INTERNATIONAL LLC
          <br />
          Sharjah Media City,
          <br />
          Dubai, United Arab Emirates.
          <br />
          License number 2011218.01
          <br />
          <br />
          Effective Date: April 3, 2023
        </p>
      </div>
    </>
  );
}
