import React from "react";

export default function Hero() {
  return (
    <>
      <div
        className="container-fluid bg-image-top "
        style={{ backgroundImage: `url(/Images/12.png)`,zIndex:'-2' }}
      >
        <div
          style={{ minHeight: "100vh", }}
          className="container bg-image-top-container justify-content-center d-flex align-items-center flex-wrap"
        >
          <div className="row w-100">
            <div className="col-md-7 row">
              <div className="col-md-6">
                <h1 className="w-100 text-md-start">
                  <b>Strategic</b>
                </h1>
                <h1 className="w-100 text-md-end">
                  <b>Digital</b>
                </h1>
              </div>
              <div className="row w-100 mx-auto">
                <div className="col-md-2"></div>
                <div className="col-md-10 mt-5">
                  <p className="justify">
                    We help clients drive their businesses and brands, whether
                    as an external marketing department or to support their
                    marketing and communication departments.
                  </p>

                </div>
              </div>
            </div>
            <div className="col-md-5 row mx-auto">
              <div className="col-md-2"></div>
              <div className="col-md-10">
                <p className="justify">
                  We are a strategic digital marketing consultancy specialized
                  in the development of visual identity and digital marketing
                  projects to provide full support to clients and their
                  businesses, through close communication and a deep
                  understanding of their issues.
                </p>
              </div>
              <div className="col-md-6 w-100 mt-5">
                <h1 className="w-100 text-md-end">
                  <b>Marketing</b>
                </h1>
                <h1 className="w-100 text-md-start">
                  <b>Consultancy</b>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}
