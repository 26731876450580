import React from "react";

export default function Hero() {
  return (
    <>
      <div
        className="container-fluid bg-image-top"
        style={{ backgroundImage: `url(/Images/bg.jpg)`, }}
      >
        <div
          style={{ minHeight: "100vh" }}
          className="container bg-image-top-container justify-content-center d-flex align-items-center flex-wrap"
        >
          <div className="row w-100 d-flex justify-content-center align-items-center">
            <div className="col-lg-6 col-md-9 ">
              <div className="col-md-10">
                <p>
                  <b>Services</b>
                </p>
                <h1>Osiris Marketing</h1>
                <div
                  className="w-100 mx-auto mb-5  join-us-line"
                  style={{
                    height: "fit-content ",
                    padding: "1.9px",
                    backgroundColor: "white ",
                  }}
                ></div>
              </div>
              <div className="col-12 row">
                <div className="col-md-6">
                  <div className="col-12">
                    At Business Advisor, we offer a wide range of services to
                    help businesses achieve their goals and maximize their
                    potential. We work closely with our clients to identify
                    improvement opportunities and develop customized solutions
                    that enable them to effectively and efficiently reach their
                    objectives.
                    <br />
                    <a href="#bussines-advisor">
                      <button className="btn bg-light rounded-5 my-3 ">
                        <b className="primary-color">Business Advisor</b>
                      </button>
                    </a>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="col-12">
                    At Market Analysis, we provide market analysis services to
                    help businesses better understand their industry,
                    competitors, and customers. Our team of market research
                    experts uses various tools and techniques to gather
                    information and generate comprehensive and accurate reports.
                    <br />
                    <a href="#market-analysis">
                      <button className="btn bg-light rounded-5 my-3 ">
                        <b className="primary-color">Market Analysis</b>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
