import React from "react";
import ReactFlow, { Background, Controls, MiniMap } from 'react-flow-renderer';

export default function MarketAnalysis() {
  return (
    <>
      <div className="container rounded-5 market-analysis" id="market-analysis">
        <div className="row p-5">
          <div className="col-md-2 text-center">
            <img src="Images/3x/5.png" width={100} />
          </div>
          <div className="col-md-6 ">
            <h4>Market analysis</h4>
            <p className="justify">
              <b>We advise and guide companies</b> on the best strategies and
              ways to approach their potential customers, and we do it through
              <b>
                new business model opportunities, enhancing marketing activities
                and improving customer service efforts and sales
              </b>
              , all thanks to the previous analysis of market data, achieving a
              significant impact on their business.
            </p>
          </div>
          <div className="col-md-4 market-research">
            <h4>Market research:</h4>
            <p className="justify">
              We conduct thorough market research to help businesses better
              understand their target audience, needs, and wants. We use
              surveys, interviews, and data analysis to gather valuable insights
              about the market and customers.
            </p>
          </div>
          <div className="col-md-4 compititor-analysis">
            <h4>Competitor analysis</h4>
            <p className="justify">
              We identify our clients' major competitors and evaluate their
              strengths and weaknesses. We also evaluate market trends and
              growth opportunities to help businesses differentiate themselves
              from the competition.
            </p>
          </div>
          <div className="col-md-7 market-logo text-center">
            <img src="Images/logo.png" className="w-75" />
          </div>
          <div className="col-md-4 trend-analysis compititor-analysis">
            <h4>Trend analysis:</h4>
            <p className="justify">
              We are constantly monitoring the latest trends and changes in the
              industry to help businesses stay up to date and adapt to new
              market conditions. We provide regular reports on market trends and
              best practices to help businesses make informed decisions.
            </p>
          </div>
          <div className="col-md-8 ps-5 customer-satisfiaction">
            <h4>Customer satisfaction analysis:</h4>
            <p className="justify">
              We evaluate the satisfaction of our clients' customers to help
              them improve the quality of their products and services and
              strengthen their customer relationships.
            </p>
          </div>
          <div className="col-12 pt-4">
            <p className="justify">
              Our market analysis reports are customized and tailored to each
              client's specific needs. We work closely with our clients to
              ensure they receive relevant and useful information for their
              business objectives.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
