import React, { useState,useEffect } from 'react'
export default function ScrollToTopButton() {
        const [isVisible, setIsVisible] = useState(false);

        useEffect(() => {
            const toggleVisibility = () => {
              if (window.pageYOffset > 300) {
                setIsVisible(true);
              } else {
                setIsVisible(false);
              }
            };

            window.addEventListener('scroll', toggleVisibility);

            return () => {
              window.removeEventListener('scroll', toggleVisibility);
            };
          }, []);

          const scrollToTop = () => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          };
  return (
    <div className="scroll-to-top">
    {isVisible && (
      <button onClick={scrollToTop} className="scroll-to-top__button">
        &#8593; {/* Up arrow symbol */}
      </button>
    )}
  </div>
  )
}
