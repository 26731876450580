import React from "react";
import LegalPageNavbar from "../Components/LegalPageNavbar";
import MetaData from "../Components/MetaData";

export default function Cookies() {
  return (
    <>
      <MetaData title="Cookie Policy" />
      <LegalPageNavbar />
      <div
        style={{
          backgroundImage: `url(/Images/citizen-petition.jpg)`,
        }}
        className="container rounded-5 shadow cookies bg-image-top-container justify-content-center d-flex align-items-center flex-wrap"
      >
        <h1 className="primary-color cookie-heading">
          COOKIE POLICY OF OSIRIS INTERNATIONAL LLC
        </h1>
        <div
          className="p-3 dashed-border"
          style={{
            minHeight: "100vh",
            width: "50%",
            position: "absolute",
            left: "-20px",
          }}
        ></div>
      </div>
      <div className="container py-5 cookie-content">
        <h2>Overview</h2>
        <p>
          This cookie policy pertains to the website of Dubai-based company
          specialized in the advisory, consultancy and development of visual
          identity and digital marketing projects, OSIRIS INTERNATIONAL LLC. We
          use cookies and similar technologies on our website to enhance the
          user experience and gain insights into the usage of our website.
        </p>

        <h2>What are Cookies?</h2>
        <p>
          Cookies are small text files that are saved on your computer or mobile
          device when you visit a website. These files serve to enhance the user
          experience and provide website owners with information about how their
          website is used.
        </p>

        <h2>Types of Cookies We Use</h2>
        <p>Our website uses the following types of cookies:</p>
        <ol type="a">
          <li>
            <strong>Essential cookies:</strong> These cookies are essential for
            the basic functioning of our website and cannot be disabled in our
            systems.
          </li>
          <li>
            <strong>Performance cookies:</strong> These cookies enable us to
            collect data about the most frequently visited pages and any error
            messages you may encounter. We use this information to enhance the
            performance and user experience of our website.
          </li>
          <li>
            <strong>Functionality cookies:</strong> These cookies help us
            remember your preferences on our website, such as your preferred
            language, and provide a personalized experience.
          </li>
          <li>
            <strong>Advertising cookies:</strong> These cookies are employed to
            display ads that are more relevant to your interests. They are also
            used to limit the frequency of ad displays and measure the
            effectiveness of advertising campaigns.
          </li>
        </ol>

        <h2>How to Control Cookies?</h2>
        <p>
          You can manage and control cookies on your computer or mobile device
          through your browser settings. It is important to note that disabling
          cookies may affect the functionality of our website.
        </p>

        <h2>How to Obtain More Information?</h2>
        <p>
          If you have any concerns or queries regarding our cookie policy,
          please do not hesitate to contact us at{" "}
          <a href="mailto:info@osirismarketing.io">
            <b>info@osirismarketing.io</b>
          </a>
        </p>
        <p>
          By using our website, you agree to the use of cookies as specified in
          this cookie policy. If you do not consent to the use of cookies, you
          should disable them through your browser settings or refrain from
          using our website.
        </p>
      </div>
    </>
  );
}
