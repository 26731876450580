import React from "react";

export default function PeopleEmpowering() {


  return (
    <div className=" border-top" id="people">
      <div className="container py-5">
        <div className="row pb-5">
          <div className="col-12 text-center">
            <b>
              <h1 className="design-heading-res" style={{ fontSize: "5rem" }}>People Empowering</h1>
            </b>
            <p className="my-3">
              <b>
                {" "}
                At our marketing company, we know that the success of any
                organization is based on the people who make it up. That's why
                we offer the People Empowering service, designed to help
                businesses enhance the talent and capability of their human
                team.
              </b>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-7">
            <div
              className="col-12 rounded-5 zoom-div p-5 justify people-power-div"
              style={{ backgroundImage: "url(Images/5.png)" }}
            >
              <div className="people-power-div-content">
                At our company, we pride ourselves on offering a comprehensive
                service that is tailored to meet the specific needs of our
                clients. Our service is based on providing dedicated and ad-hoc
                support to guide and train our clients in the use of the most
                appropriate technology and solutions for their type of business
                and volume of activity.
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div
              className="w-75 mx-auto rounded-5 zoom-div people-div-2 p-5 justify people-power-div"
              style={{ backgroundImage: "url(Images/5.png)", height: "400px" }}
            ></div>
          </div>
          <div className="col-md-6">
            <div
              className="w-100 mx-auto rounded-5 zoom-div people-div-3 p-5 justify people-power-div"
              style={{ backgroundImage: "url(Images/3.png)", height: "550px" }}
            ></div>
          </div>
          <div className="col-md-6">
            <div
              className="col-12 rounded-5 zoom-div p-5 justify mt-3 people-power-div"
              style={{
                backgroundImage: "url(Images/6.png)",
                backgroundPosition: "top",
              }}
            >
              <div className="people-power-div-content">
                We believe that technology is a powerful tool that can help
                businesses achieve their goals and grow their operations.
                However, we also understand that not all businesses have the
                same needs or resources, which is why we take a customized
                approach to our service.
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div
              className="col-12 rounded-5 zoom-div p-5 justify mt-3 people-power-div"
              style={{
                backgroundImage: "url(Images/2.png)",
                backgroundPosition: "top",
              }}
            >
              <div className="people-power-div-content">
                In addition to our technology support, we offer training
                programs that are designed to help people develop new skills,
                knowledge of new competencies, expertise, and experience. We
                believe that investing in the professional development of our
                clients is essential for their long-term success, and our
                training programs are an excellent way to achieve this.
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div
              className="w-100 mx-auto rounded-5 zoom-div people-div-6 p-5 justify people-power-div"
              style={{ backgroundImage: "url(Images/1.png)", height: "550px" }}
            ></div>
          </div>
          <div className="col-md-6">
            <div
              className="col-12 rounded-5 zoom-div p-5 people-div-7 justify mt-3 people-power-div"
              style={{
                backgroundImage: "url(Images/4.png)",
              }}
            >
              <div className="people-power-div-content">
                Our training programs cover a wide range of topics, including
                the latest technology trends, industry best practices, and
                leadership and management skills. We work closely with our
                clients to identify their specific training needs and develop a
                customized program that meets those needs.
              </div>
            </div>
          </div>
          <div className="col-md-6"></div>
          <div className="col-md-6 my-5">
            <div
              className="w-100 mx-auto rounded-5 zoom-div p-5 justify people-power-div"
              style={{ backgroundImage: "url(Images/7.png)", height: "550px" }}
            ></div>
          </div>
          <div className="col-md-6">
            <div
              className="col-12 rounded-5 zoom-div p-5  justify mt-5 people-power-div"
              style={{
                backgroundImage: "url(Images/4.png)",
              }}
            >
              <div className="people-power-div-content">
                Whether you are looking to improve your team's technical skills,
                develop your own leadership abilities, or simply stay up-to-date
                with the latest trends in your industry, our training programs
                can help you achieve your goals.
              </div>
            </div>
          </div>
          <div className="col-md-6"></div>
          <div className="col-md-6">
            <div
              className="col-12 rounded-5 zoom-div p-5 justify col-div-10 people-power-div"
              style={{
                backgroundImage: "url(Images/4.png)",
              }}
            >
              <div className="people-power-div-content">
                If you are interested in learning more about our comprehensive
                service and how we can help you achieve your business goals. We
                would be happy to discuss your needs and develop a customized
                solution that is right for you.
                <div className="col-12 d-flex justify-content-around">
                  <a href="#contact" className="btn bg-light rounded-5  my-3 ">
                    <b className="primary-color">Send a Message</b>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
