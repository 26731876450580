import React from "react";

export default function EliminateProblems() {
  return (
    <>
      <div className="container-fluid py-5 eleminate-problem">
        <div className="container py-5">
          <div className="row pt-5">
            <div className="col-md-10 col-lg-8">
              <h1>
                We Eliminate Problem
                <br />
                by Providing Solutions.
              </h1>
              <p>
                No matter what kind of small business you run, you want to
                increase your sales and grow your revenue, That’s why so many
                people are interested in how to grow a small business.
              </p>
            </div>
          </div>
          <div className="row my-4 pt-5 border-left-only-dot px-3 col-md-10 col-lg-9 mx-auto">
            <div className="w-100" id="div1">
              <h3 className="heading-line-left">Increase Customer Retention</h3>
              <p>
                It’s not enough to just get new customer for your business. You
                also need to keep your customers.
              </p>
            </div>
            <div className="col-md-10 my-4 col-lg-8" id="div2">
              <h3 className="heading-line-left">
                Prioritizing customer service
              </h3>
              <p>
                If you don’t treat your customers right, they won’t want to
                support your business. Make sure they do.
              </p>
            </div>
            <div className="col-md-10 my-4 col-lg-8" id="div3">
              <h3 className="heading-line-left">
                Participe in Networking Events
              </h3>
              <p>
                Check local professional organizations and don’t be afraid to
                attend a few events to get the word.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
